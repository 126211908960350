* {
    word-break: break-word;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

.loaderServices {
    padding-top: 35vh;
}

.blockServices {
    min-height: calc(100vh - 80px - 50px - 78px);
}

.mainBlockContent {
    flex-wrap: wrap;
    display: flex;
    padding: 0 10% 0 10%;
    justify-content: center;
    margin-top: 50px;
}

.namePriceList {
    width: 100%;
    border-bottom: 1px solid black;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    line-height: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}


@media (max-width: 720px) {
    .namePriceList {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
}