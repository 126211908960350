.main {
    width:100vw;
    display: flex;

    * {
        text-decoration: none!important;
    }
}


.leftMenu {
    width: 210px!important;
    height: 100vh!important;
}

.mainContent {
    width: calc(100vw - 210px)!important;
    padding-top: 78px!important;
}




@media (max-width: 720px) {
    .leftMenu {
        width: 0!important;
    }
    .mainContent {
        width: 100%!important;
    }
}