.card {
    width: 20%!important;
    margin: 1%!important;
}

.modal {
    height: auto;
    position: relative!important;
    button {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        padding: 0!important;
        background-color: transparent!important;
        color: #464646!important;
        font-size: 2rem!important;
    }
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .imageInModal {
        flex: 0 1 auto!important;
        height: auto!important;
        font-size: 1rem!important;
        min-height: 300px!important;
        img{
            display: block!important;
            width: 300px;
            height: auto;
        }
    }
    .mainContent {
        width: 300px;
        padding: 15px;
    }
    .price {
        font-size: 18px;
        bottom: 3.5rem;
        position: absolute;
        right: 3.5rem;
    }
}

@media (min-width: 720px) and (max-width: 1024px) {
    .card {
        width: 30%!important;
        * {
            font-size: 1.1rem!important;
        }
    }
}


@media (max-width: 720px) {
    .card {
        width: 40%!important;
        margin: 2%!important;
        * {
            line-height: 1rem;
            font-size: 0.9rem!important;
        }
    }
    .modal {
        .price {
            bottom: 2rem!important;
        }
        .imageInModal {
            img{
                width: 100%!important;
            }
        }    
        .header {
            font-size: 1.2rem!important;
        }
    }
}