$widthImageBlock: 45%;
$withContentBlock: 55%;


.cardBlock {
    * {
        font-family: "Helvetica", Hekvetica;
        line-height: normal;
    }
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    margin-bottom: 80px;

    .imageBlock {
        width: $widthImageBlock;
        img {
            height: 700px;
            width: 100%;
            vertical-align: middle;
            display: inline-block;
            text-align: left;
            object-fit: cover;
            object-position: top;
        }
    }

    .contentBlock {
        width: $withContentBlock;
        padding: 3rem;
        div {
            width: 100%;
        }
        .title {
            font-size: 48px;
            font-weight: 500;
            color: #000000;
            align-items: flex-end;
        }

        .infoText {
            color: #595959;
            font-weight: 400;
            font-size: 18px;
        }
    }
}

.cardBlock:nth-child(3) img {
    object-position: top;
}
.cardBlock:nth-child(4) img {
    object-position: top;
}
.cardBlock:nth-child(5) img {
    object-position: top;
}


@media (max-width: 720px) {
    $widthImageBlock: 90%;
    $withContentBlock: 90%;
    .cardBlock {
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: auto;
        .imageBlock {
            width: $widthImageBlock;
            display: inherit;
        }
        .contentBlock {
            width: $withContentBlock;
        }
    }
}