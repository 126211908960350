.header {
    font-size: 35px!important;
    font-weight: 500!important;
    color: #000000!important;
    font-family: "Helvetica", Hekvetica!important;
}

.textContent {
    font-size: 20px!important;
    font-weight: 400!important;
    line-height: 1.4em!important;
    color: #595959!important;
    font-family: "Helvetica", Hekvetica!important;
}

.mainBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 25px;
    min-height: calc(100% - 50px);
    .imageGallery {
        margin-bottom: 2rem;
    }
    .header {
        text-align: center;
        margin: 2rem 0 3rem 0;
    }

    .blockMap {
        display: flex;
        flex-wrap: wrap;
        .header {
            width: 100%;
        }
        .blockAddress {
            width: 40%;
            padding: 2rem;
        }
        .blockMap {
            width: 60%;
        }
        width: 100%;
    }
}

.textInform {
    width: 100%;
    display: flex;
    justify-content: center;
    .textContent {
        width: 60%;    
    }
}

.mainGoals {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    .imageGoals {
        width: 45%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .blockGoals {
        padding: 3rem;
        width: 55%;
        font-size: 20px!important;
        .header {
            margin-bottom: 1rem;
        }
        .textContent {
            font-size: 20px;
        }
    }
}




@media (max-width: 720px) {
    .textInform {
        .textContent {
            width: 80%;    
        }
    }

    .mainGoals {
        flex-direction: column;
        .imageGoals {
            width:100%;
        }
        .blockGoals {
            width: 100%;
        }
    }
}