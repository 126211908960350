.mainBlockContent {
    margin-top: 50px;
}

.loaderSpecialists {
    padding-top: 35vh;
}

.blockSpecialist {
    min-height: calc(100vh - 80px - 50px - 78px);
}