.nav {
    position: fixed;
    float: left;
    font-size: 12.5px;
    z-index: 99;

    * {
        color: #bababa!important;
        text-transform: unset!important;
    }

    a:hover {
        color: #ffffff!important;
        border-radius: 115px;  
        transition: .3s!important;
    }

    img {
        display: inline-block!important;
        margin-bottom: 5px;
        width: 50px;
    }
    div {
        flex-grow: 1;
        display:flex; 
        align-items:center;
    }
    .phoneNumberBlock {
        display: none;
    }
}


.blockDesc {
    background-color: #262626!important;
    display:flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topContent {
    height: 20%;
    .topContentMobile {
        display: none;
    }
    * {
        color: white!important;    
    }
}


.mainContent {
    height: 50%;
}

.bottomContent {
    height: 30%;
    font-size: 11px!important;
    i {
        font-size: 30px!important;
        float: none!important;
    }
}

.blockMobile {
    width: 100vh!important;
    height: 50px!important;
}


@media (max-width: 720px) {
    .nav {
        width: 100%;
        .blockDesc {
            height: auto;
            justify-content: center;
            padding: 0;
            .topContent {
                width: 100%;
                margin: 0;
                width: 100%;
                .topContentIconLink {
                    display: none;
                }
                .topContentMobile {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    .linkIcon {
                        width: 10%;
                        padding: 7px;
                        img {
                            margin: 0;
                        }
                    }
                    a {
                        text-align: left;
                        padding-left: 2rem;
                    }
                    p {
                        text-align: left;
                        padding: 0 15px;
                        margin: 0;
                    }
                    .buttonMenu {
                        justify-content: flex-end;
                        padding: 5px;
                        cursor: pointer;
                    }
                }
            }
            .mainContent {
                width: 100%;
                margin: 0;
                .activeMobileButton {
                    background-color: #333333!important;
                }

            }
            .bottomContent {
                display: none;
            }
        }

        .phoneNumberBlock {
            display: block;
            position: absolute;
            top: 40px;
            background-color: #ffffff;
            width: 100%;
            text-align: center;
            * {
                font-size: 17px !important;
                padding: 2px;
                color: #000000!important;
            }
        }

    }
}