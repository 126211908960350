.mainBlockContent {
    * {
        font-family: "Helvetica", Hekvetica;
        line-height: normal;
    }
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    .header {
        width: 80%;
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        color: #000000;
    }

    .textBlock {
        width: 100%;
        padding: 5rem;
        color: #2f2f2f;
        font-weight: 400;
        font-size: 18px;
    }

    .fullName {
        font-size: 22px;
        color: #000000;
    }
}


@media (max-width: 1024px) {
    .mainBlockContent {
        .header {
            font-size: 30px;
        }

        .textBlock {
            padding: 2rem;
            font-size: 22px;
        }
        .fullName {
            font-size: 25px;
        }
    }
}