@media (max-width: 720px) {
    #mainMenuBlockTop {
        display: none;
    }
    #mainMenuBlockTop a {
        width: 100%!important;
    }
    #mainMenuBlockTop .active {
        background-color: #333333!important;
        color: #ffffff!important;
    }
    #btnMainMenuIsClose {
        display: none;
    }
}