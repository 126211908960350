$height: 3px;

.elementorDivider {
    margin: 30px 0 30px-$height 0;
    width: 100%;
    display: flex;
    .elementorDividerSeparator {
        width: 65px;
        background-color: #000;
        height: $height;
    }

}

@media (max-width: 720px) {
    .elementorDivider {
        justify-content: center;
    }
}