$widthImageBlock: 45%;
$withContentBlock: 55%;

.mainBlockContent {
    margin-top: 50px;
}

.cardBlock {
    * {
        font-family: "Helvetica", Hekvetica;
        line-height: normal;
    }
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    margin-bottom: 80px;

    .imageBlock {
        width: $widthImageBlock;
        img {
            height: 100%;
            max-width: 600px;
            width: 100%;
            vertical-align: middle;
            display: inline-block;
            text-align: left;
            object-fit: cover;
        }
    }

    .contentBlock {
        width: $withContentBlock;
        padding: 3rem;
        div {
            width: 100%;
        }
        .title {
            font-size: 48px;
            font-weight: 500;
            color: #000000;
            align-items: flex-end;
        }

        .infoText {
            color: #595959;
            font-weight: 400;
            font-size: 18px;
        }
    }
}

.cardBlock:nth-child(3) img {
    object-position: 20%;
}
.cardBlock:nth-child(4) img {
    object-position: 70%;
}
.cardBlock:nth-child(5) img {
    object-position: 20%;
}


@media (max-width: 720px) {
    $widthImageBlock: 90%;
    $withContentBlock: 90%;
    .cardBlock {
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: auto;
        .imageBlock {
            height: 100%;
            width: $widthImageBlock;
            display: inherit;
        }
        .contentBlock {
            width: $withContentBlock;
            padding: 0;
            padding-top: 2rem;
            .title {
                font-size: 40px;
            }
        }
    }
}

@media (min-width: 721px) and (max-width: 1280px){
    .cardBlock {
        .imageBlock {
            width: $widthImageBlock;
            img {
                height: 700px;
            }
        }
    }
}