.nav {
    left: 210px;
    position: fixed;
    padding: 8px 0 8px 0;
    width: calc(100vw - 210px)!important;
    font-size: 12.5px;
    height: 78px;
    z-index: 99;
    background-color: #fff;

    img {
        width: 45px;
    }
    * {
        text-align: center;
        margin: 0!important;
        padding: 0!important;
        color: #000000!important;
        box-shadow: none!important;
        border: none!important;
    }
    div {
        display: flex!important;
        justify-content: center!important;
        height: 78px;
    }

    .phoneNumber {
        padding-top: 22px!important;
        font-size: 17px!important; 
        color: #525252;
        i { margin-right: 10px!important;}
    }
}


@media (max-width: 720px) {
    .nav {
        display: none!important;
    }
}

