* {
    word-break: break-word;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

.blockContacts {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    font-family: "Helvetica", Hekvetica;
    * {
        color: #131313!important;    
    }
    .header {
        width: 100%;
        text-align: center;
        font-size: 48px;
        height: 48px;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .blockAddress {
        width: 40%;
        padding: 0 2rem;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2em;
        div {
            margin-bottom: 2rem;
            p {
                margin: 0;
            }
        }
        p:first-child {
            font-size: 23px;
            margin-bottom: 0.3rem;
        }
    }

    .blockMap {
        padding: 0 3rem;
        width: 60%;
    }
}






@media (max-width: 1024px) {
    .blockAddress {
        width: 100%!important;
        text-align: center;
    }
    .blockMap {
        padding: 0!important;
        height: 300px;
        margin-top: 2rem;
        width: 100%!important;
    }
}